import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import BannerIMG from "../assets/img/banner.jpg";

export function Header() {
  return (<>

    <Container fluid className="p-0">
        <Row className="m-0">
            <Col className="p-0">
                <img src={BannerIMG} alt="WhatTheLuck" className="img-fluid w-100" />
            </Col>
        </Row>
    </Container>
    
    <div id="wt7-header" className="text-secondary px-4 text-center">
      <div className="py-4">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <div className="connect-zone">
                  <ConnectButton />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <div className="b-divider"></div>
    </>);
}