import React, { useState, useEffect } from 'react';

function Countdown({ initialTimestamp, neededTime, onCompletion }) {
    const [currentTimestamp, setCurrentTimestamp] = useState(Date.now());
    const [difference, setDifference] = useState(((initialTimestamp + neededTime) * 1000) - currentTimestamp);
 
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTimestamp(Date.now());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    useEffect(() => {
        setDifference(((initialTimestamp + neededTime) * 1000) - currentTimestamp);
        
        if (difference <= 0 && onCompletion) {
          onCompletion();
        }
    }, [currentTimestamp, initialTimestamp, onCompletion]);
  
    const seconds = Math.floor(difference / 1000) % 60;
    const minutes = Math.floor(difference / (1000 * 60)) % 60;
    const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
 
    if (difference <= 0) {
        return null; 
    }

    return (
      <span>{minutes}m {seconds}s restants avant de pouvoir mint à nouveau.</span>
    );
}

export default Countdown;