import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import wantedBossIMG from "../assets/img/wanted-boss.jpg";
import wantedKingIMG from "../assets/img/wanted-king.jpg";
import wantedEmperorIMG from "../assets/img/wanted-emperor.jpg";
import wantedGodIMG from "../assets/img/wanted-god.jpg";

export function Footer() {
  const contractAddress = process.env.REACT_APP_SC_ADDRESS;
  const contractChainExplorer = process.env.REACT_APP_SC_CHAIN_EXPLORER;
  const version = process.env.REACT_APP_VERSION;

  return (
    <div>
      <div className="b-divider"></div>

      <div id="wt7-footer" className="text-secondary px-4 text-center">
        <div className="py-4">

          <p><a href="https://marketplace.whatthe7uck.com" target="_blank">WT7 Marketplace</a> &bull; <a href="https://rarible.com/whatthe7uck/items" target="_blank">Rarible</a> &bull; <a href="https://opensea.io/collection/whatthe7uck" target="_blank">OpenSea</a> &bull; <a href="https://discord.gg/Mn8sScdJmX" target="_blank">Discord</a> &bull; <a href="https://x.com/BullBiDoo" target="_blank">X (Twitter)</a></p>
          <span className="sc">
            Smart Contract : <a href={`https://${contractChainExplorer}/address/${contractAddress}`} target="_blank" rel="noreferrer">{contractAddress}</a>
          </span>
          <br />
          <span className='made-with-love'>
            DApp v.{version} made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank" rel="noreferrer"> RpGmAx</a>
          </span>
        </div>
      </div>

      <Container>
        <Row>
          <Col xs={3}><img src={wantedBossIMG} className="img-fluid mb-4" alt="Wanted Boss" /></Col>
          <Col xs={3}><img src={wantedKingIMG} className="img-fluid mb-4" alt="Wanted King" /></Col>
          <Col xs={3}><img src={wantedEmperorIMG} className="img-fluid mb-4" alt="Wanted Emperor" /></Col>
          <Col xs={3}><img src={wantedGodIMG} className="img-fluid mb-4" alt="Wanted God" /></Col>
        </Row>
      </Container>

    </div>
  );
}