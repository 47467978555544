import { useState } from 'react';
import { Container, Row, Col, Form, Button, Badge } from 'react-bootstrap';
import { useContractWrite } from 'wagmi';
import { useAccount } from 'wagmi';

import abi from './contracts/ABI.json';

export function Giveaway() {

  const { address, isConnected } = useAccount();
  
  const [addresses, setAddresses] = useState("");

  const { data, isLoading, isSuccess, write } = useContractWrite({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'giveaway',
    onSuccess(data) { console.log(data) },
    onError(data) { console.log(data) }
  })

  const handleGiveaway = async () => {
      const addressesList = addresses.split("\n").filter(addr => addr.trim() !== '');
      console.log('Giveaway démarré à destination de ' + addressesList.length + ' adresses !');

      for (let currentAddress of addressesList) {
          try {
            write({ args: [currentAddress, 3] })
          } catch (error) { console.error(error); }
      }
  };

  if (!isConnected) return <Badge className="bg-danger my-4">Veuillez connecter votre wallet :)</Badge>;

  return (<>
    <Container className="col-xl-10 col-xxl-8 px-4">
      <Row className="py-2">

        <p className="mt-2">C'est l'heure du giveaway :)</p>
        <Col className="mx-auto">
            <Form.Control as="textarea" rows={10} value={addresses} onChange={(e) => setAddresses(e.target.value)} placeholder="Une adresse par ligne" />
            <Button type="submit" className="my-4 w-100 btn-lg btn-light" onClick={handleGiveaway}>Giveaway</Button>
        </Col>
      </Row>
    </Container>
  </>);
}