import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { 
  getDefaultWallets, 
  RainbowKitProvider 
} from '@rainbow-me/rainbowkit';
import { 
  configureChains, 
  createConfig, 
  WagmiConfig 
} from 'wagmi';
import { polygon } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Mint } from './Mint';
import { Giveaway } from './Giveaway';

// Configuration Setup
const { chains, publicClient } = configureChains(
  [polygon],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'What The 7uck',
  projectId: 'ca1d84b546cae0dc74f0740f83387bf4',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Header />
        <Router>
          <Routes>
            <Route exact path="/:forcedTokenId" element={<Mint />} />
            <Route path="/" element={<Mint />} />
            <Route path="/Giveaway" element={<Giveaway />} />
          </Routes>
        </Router>
        <Footer />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;