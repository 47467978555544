import { useState, useEffect } from 'react';
//import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Badge, ProgressBar } from 'react-bootstrap';
import { useContractRead, useContractEvent, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { useAccount } from 'wagmi';

import abi from './contracts/ABI.json';
import Countdown from './components/Countdown'; 
import { fromHex } from 'viem'

import attributes from './contracts/attributes.json';

import soldOutIMG from "./assets/img/soldout.jpg";

const MintProgressBar = ({ attribute, variant, label }) => (
  attribute ? (
    <div style={{ position: 'relative' }}>
      <ProgressBar now={attribute.progressPercent} striped variant={variant} animated className="my-4" />
      <div className="progressbar-label" style={{ minWidth: attribute.progressPercent === 0 ? '40px' : '0px' }}>
        {`${label} : ${attribute.phase}`}
      </div>
    </div>
  ) : null
);

export function Mint() {

  const { address, isConnected } = useAccount();
  const allowlistServer = "https://allowlist.rpgmax.fr/wt7/";

  const [mintPrice, setMintPrice] = useState(0);
  const [currentPhase, setCurrentPhase] = useState(0);
  const [isFreeMintTime, setIsFreeMintTime] = useState(false);
  const [freeMintStartTime, setFreeMintStartTime] = useState(0);
  const [allowedFreeMints, setAllowedFreeMints] = useState(0);
  const [usedFreeMints, setUsedFreeMints] = useState(0);
  const [mtProof, setMtProof] = useState([]);

  const [mintedTokenId, setMintedTokenId] = useState(null);

  const [isPhaseSoldOut, setIsPhaseSoldOut] = useState(false);

  //const { forcedTokenId } = useParams();

  useEffect(() => {
    async function fetchDetails(address) {
      if (!address) return;

      try {
        const response = await fetch(`${allowlistServer}${address}`);
        const details = await response.json();
        
        setAllowedFreeMints(details[0]);
        setMtProof(details[1] || []);
      } catch (err) { console.log(err); }
    }

    fetchDetails(address);
  }, [address]);

  // Lectures SC
  useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'freeMintStartTime',
    watch: true,
    onSuccess(data) { setFreeMintStartTime(parseInt(data)); }
  })

  useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'currentPhase',
    onSuccess(data) { setCurrentPhase(parseInt(data)); }
  })

  useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'mintPrice',
    onSuccess(data) { setMintPrice(data); }
  })

  useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'isFreeMintTime',
    onSuccess(data) { setIsFreeMintTime(data); }
  })

  useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'getFreeMintedTokensByAddress',
    args: [address],
    enabled: Boolean(address),
    watch: true,
    onSuccess(data) { setUsedFreeMints(parseInt(data)) }
  })

  useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'availableNumbersByPhase',
    args: [currentPhase, 0],
    enabled: Boolean(address && currentPhase >= 1 && currentPhase <= 3),
    onSuccess() { setIsPhaseSoldOut(false) },
    onError() { setIsPhaseSoldOut(true) }
  })

  useContractEvent({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    eventName: 'PhaseUpdate',
    listener(log) {
      var newCurrentPhase = parseInt(log[0].args.phaseID);
      var newIsFreeMintTime = log[0].args.isFreeMintTime;
      setCurrentPhase(newCurrentPhase);
      setIsFreeMintTime(newIsFreeMintTime);

      if (newCurrentPhase >= 1 && newCurrentPhase <= 3) {
        if (newIsFreeMintTime) {
          freeMintRefetch();
        } else {
          mintRefetch();
        }
      }

    },
  })

  // Écritures SC
  const {
    config: freeMintConfig,
    error: freeMintPrepareError,
    isError: freeMintIsPrepareError,
    isSuccess: freeMintIsPrepareSuccess,
    refetch: freeMintRefetch,
  } = usePrepareContractWrite({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    args: [allowedFreeMints, mtProof],
    functionName: 'freeMint',
    enabled: Boolean(address && isFreeMintTime && currentPhase >= 1 && currentPhase <= 3)
  });

  const {
    config: mintConfig,
    error: mintPrepareError,
    isError: mintIsPrepareError,
    isSuccess: mintIsPrepareSuccess,
    refetch : mintRefetch,
  } = usePrepareContractWrite({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'mint',
    value: mintPrice,
    enabled: Boolean(address && !isFreeMintTime && currentPhase >= 1 && currentPhase <= 3)
  });

  const { data: freeMintData, write: freeMintWrite } = useContractWrite(freeMintConfig);
  const { isLoading : freeMintIsLoading, isSuccess: freeMintIsSuccess } = useWaitForTransaction({
    hash: freeMintData?.hash,
    onSuccess(txData) {
      var mintedTokenId = fromHex(txData.logs[1].data, 'number')
      setMintedTokenId(mintedTokenId);
    }
  });

  const { data: mintData, write: mintWrite } = useContractWrite(mintConfig);
  const { isLoading : mintIsLoading, isSuccess: mintIsSuccess } = useWaitForTransaction({
    hash: mintData?.hash,
    onSuccess(txData) {
      var mintedTokenId = fromHex(txData.logs[2].data, 'number')
      setMintedTokenId(mintedTokenId);
    }
  });

  useEffect(() => { if(freeMintIsSuccess) freeMintRefetch() }, [freeMintIsSuccess]);
  useEffect(() => { if(mintIsSuccess) mintRefetch() }, [mintIsSuccess]);

  useEffect(() => { if(freeMintIsLoading == true || mintIsLoading == true) setMintedTokenId(null); }, [freeMintIsLoading, mintIsLoading]);
  //useEffect(() => { if(forcedTokenId) setMintedTokenId(parseInt(forcedTokenId)); }, [forcedTokenId]);


// Messages d'erreur
  const friendlyErrorMessage = (errorMsg) => {
    const errorMappings = {
      "Hey, you aren't on the allowlist :/": "Vous n'avez pas de free mint, RDV en phase publique",
      "Hey, no more free mint allowed !": "Vous n'avez plus de free mint, RDV en phase publique",
      "No more supply for that phase !" : "Cette phase de mint est sold out, RDV à la prochaine",
      "Free mint supply reached !" : "Cette phase de free mint est sold out, RDV à la prochaine",

      "Whale detected !": () => (
        <span>
          Protection anti whale activée. <Countdown initialTimestamp={freeMintStartTime} neededTime={903} onCompletion={freeMintRefetch}  />
        </span>
      ),
  };

    const errorMessage = errorMappings[errorMsg];
  
    if (typeof errorMessage === 'function') { return errorMessage(); }
    return errorMessage || errorMsg;
  };

  const getFreeMintButtonText = () => {
    if (freeMintIsLoading) return 'Mint en cours...';
    //if (freeMintIsSuccess) return 'Mint réussi';
    if (freeMintIsPrepareError && freeMintPrepareError.cause && freeMintPrepareError.cause.reason) return friendlyErrorMessage(freeMintPrepareError.cause.reason);
    if (freeMintIsPrepareError) return 'Mint impossible (vérifiez que vous avez suffisament de MATIC)';
    var plural = allowedFreeMints > 1 ? "s" : "";
    return 'Free Mint - ' + usedFreeMints + '/' + allowedFreeMints + ' utilisé' + plural;
  };

  const getMintButtonText = () => {
    if (mintIsLoading) return 'Mint en cours...';
    //if (mintIsSuccess) return 'Mint réussi';
    if (mintIsPrepareError && mintPrepareError.cause && mintPrepareError.cause.reason) return friendlyErrorMessage(mintPrepareError.cause.reason);
    if (mintIsPrepareError && isPhaseSoldOut) return 'Cette phase de mint est sold out, RDV à la prochaine';

    if (mintIsPrepareError) return 'Mint impossible (vérifiez que vous avez suffisament de MATIC)';
    return 'Mint (15 MATIC)';
  };

  // Messages en fonction du rang du NFT minté
  const getMsgByRank = (tokenId) => {
    const item = attributes.find((item) => item.id === tokenId);
    if (item) {
      const rankAttribute = item.attributes.find((attr) => attr.trait_type === 'Rank');
      if (rankAttribute) {
        switch (rankAttribute.value) {
          case 'Boss':
            return <><span className="rank boss-rank">Boss</span> : 15 MATIC<br />Pas mal moussaillon !<br /><br />Au Sold Out de la phase {currentPhase} je te ferai une offre pour racheter ton <i>Boss</i> au prix du mint.</>;
          case 'King':
            return <><span className="rank king-rank">King</span> : 30 MATIC<br />Joli mint moussaillon !<br /><br />Au Sold Out de la phase {currentPhase} je te ferai une offre pour racheter ton <i>King</i> pour deux fois son prix d'achat.</>;
          case 'Emperor':
            return <><span className="rank emperor-rank">Emperor</span> : 100 MATIC<br />Moussaillon tu as le séant bordé de tagliatelles !<br /><br />Au Sold Out de la phase {currentPhase} je te ferai une offre que tu ne pourras pas refuser.<br />Pour un <i>Emperor</i> je suis prêt à lâcher 100 MATIC !</>;
          case 'God':
            return <><span className="rank god-rank">God</span> : 200 MATIC<br />Dieu du mint moussaillon !<br /><br />Au Sold Out de la phase {currentPhase} je te ferai une offre pour racheter ton <i>God</i> à un prix divin.<br />200 MATIC pour un <i>God</i>, j’espère que tu en feras bon usage.</>;
          default:
            return <>Tu as cliqué sur mint avec ton crochet moussaillon ?!<br /><br />Il est joli ton <span className="rank common-rank">{rankAttribute.value}</span>, mais je recherche uniquement <i>Boss</i>, <i>King</i>, <i>Emperor</i> et <i>God</i>.<br />On fera affaire une prochaine fois peut-être… Il te reste une chance pour mon offre ultime.</>;
        }
      }
    }
  };
    
  const getMsgByTokenId = () => {
    return getMsgByRank(mintedTokenId);
  };

  // ProgressBar
  const getMintProgressDatas = () => {
    var phase = 'En attente de lancement';
    var progressPercent = 0;
    if (currentPhase >= 1 && currentPhase <= 3) {
      phase = 'Phase ' + currentPhase;
      progressPercent = currentPhase * 33;
      if (isFreeMintTime) { phase = phase + ' (freemint)'; progressPercent -= 10; } else { phase = phase + ' (public)'; }
    }
    if (currentPhase == 4) { phase = 'Sold Out'; progressPercent = 100; }
    if (progressPercent == 99) progressPercent = 100;
    return { phase:phase, freemint:{isFreeMintTime}, progressPercent:progressPercent };
  }

  if (!isConnected) return <Badge className="bg-danger my-4">Veuillez connecter votre wallet :)</Badge>;

  return (<>
    <Container className="col-xl-10 col-xxl-8 px-4">
      <Row className="py-2">
        <Col className="mx-auto">

          <MintProgressBar attribute={getMintProgressDatas()} variant="danger" label="Étape de mint" />

          <p className="mt-4">Le p0te de Jacque$ aura peut-être une proposition indécente à vous faire.<br />
          Une offre que vous ne pourrez pas refuser... Ou peut être que si...<br />
          Rendez-vous sur Discord après le mint pour (re)découvrir le lore et tous les détails.</p>

          {currentPhase == 0 ? (<p className="m-4">Patience, le mint n'a pas encore démarré !</p>) : null }

          {currentPhase >= 1 && currentPhase <= 3 ? (<>

            {isFreeMintTime == true ? (
              <Form onSubmit={(e) => {
                e.preventDefault();
                freeMintWrite?.();
              }}>

                <Button type="submit" className={`w-100 btn-lg ${freeMintIsPrepareError ? 'btn-danger' : 'btn-light'}`} disabled={freeMintIsLoading || !freeMintIsPrepareSuccess}>
                  {getFreeMintButtonText()}
                </Button>

              </Form>
              ) : (<>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  mintWrite?.();
                }}>

                  <Button type="submit" className={`w-100 btn-lg ${mintIsPrepareError ? 'btn-danger' : 'btn-light'}`} disabled={mintIsLoading || !mintIsPrepareSuccess}>
                    {getMintButtonText()}
                  </Button>

                </Form>

              </>) }

              {mintedTokenId ? (<>
                <div className="b-divider"></div>

                <p className="text-success mt-2"><b>Mint réussi 🎉</b></p>

                <figure className="text-center">
                  <blockquote className="blockquote py-2 bg-dark">
                    <p>{getMsgByTokenId()}</p>
                  </blockquote>
                  <figcaption className="blockquote-footer">
                    Signé : <cite>Le p0te de Jacque$</cite>
                  </figcaption>
                </figure>
                
                <a href={`https://rarible.com/token/polygon/${process.env.REACT_APP_SC_ADDRESS}:${mintedTokenId}`} className="btn btn-sm btn-warning m-2" target='_blank'>Voir sur Rarible 🔎</a>
                <a href={`https://opensea.io/fr/assets/matic/${process.env.REACT_APP_SC_ADDRESS}/${mintedTokenId}`} className="btn btn-sm btn-primary m-2" target='_blank'>Voir sur OpenSea 🔎</a>
                <a className="btn btn-sm btn-danger m-2" target="_blank" href={`https://twitter.com/intent/tweet?text=𝐖𝐡𝐚𝐭𝐓𝐡𝐞𝟕𝐮𝐜𝐤%20!%0A%0AJe%20viens%20de%20%23Mint%20un%20%23WT7%20⛏🔥%0A%0APrix%20public%3A%2015%20Matic%20seulement!%0A%0AJ'espère%20recevoir%20bientôt%20une%20offre%20indécente%20🤞🏴%E2%80%8D☠%EF%B8%8F%0A15%2C%2030%2C%20100%20ou%20200%20Matic%20🤑%0A%0Aℹ%20Tous%20les%20détails%20épinglés%20ici%3A%20%40BullBiDoo%0A%0A${encodeURIComponent(`https://marketplace.whatthe7uck.com/token/POLYGON:${process.env.REACT_APP_SC_ADDRESS.toLowerCase()}:${mintedTokenId}`)}`}>Partager mon mint ! 🗣</a>         
              </>) : null }

            </>) : null }

        </Col>
      </Row>
    </Container>
    
    {currentPhase == 4 ? (
      <Container fluid className="p-0 mb-4">
        <Row className="m-0">
          <Col className="p-0">
            <img src={soldOutIMG} className="img-fluid w-100" alt="Sold Out - RDV sur le secondaire" />      
          </Col>
        </Row>
      </Container>
    ) : null }

  </>);
}